import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core';

interface ICancelButtonProps {
	id: string;
	disabled?: boolean;
	onClick: () => void;
}

export const CancelButton = ({
	id,
	disabled,
	onClick,
}: ICancelButtonProps): React.FunctionComponentElement<ICancelButtonProps> => {
	const theme = useTheme();

	const CancelButtonStyle = makeStyles({
		root: {
			color: theme.palette.error.main,
		},
	});

	const classes = CancelButtonStyle();

	return (
		<Button id={id} disabled={disabled} onClick={onClick} variant="outlined" className={classes.root}>
			Cancel
		</Button>
	);
};
