import React from 'react';

import TextField from '@material-ui/core/TextField';

import './pin-field.component.css';

export type PinFieldProps = {
	id: string;
	error: boolean;
	helperText: string;
	onChange: (newValue: string) => void;
	onEnterKey: () => void;
	value: string;
};

export const PinField: React.FunctionComponent<PinFieldProps> = (props: PinFieldProps) => {
	const { id, error, helperText, onChange, onEnterKey, value } = props;
	const isTouchDevice = 'ontouchstart' in document.documentElement;

	/* TODO: Identify the correct type to use here */
	const handleKeyDown = (event: { keyCode: number }) => {
		if (event.keyCode === 13) {
			onEnterKey();
		}
	};

	return (
		<TextField
			id={id}
			fullWidth
			type={isTouchDevice ? 'number' : 'password'}
			inputProps={{
				className: 'pin-field',
				inputMode: 'numeric',
				pattern: '[0-9]*',
			}}
			variant="filled"
			label="PIN"
			value={value}
			error={error}
			helperText={helperText}
			onChange={(event) => {
				onChange(event.target.value);
			}}
			onKeyDown={handleKeyDown}
		/>
	);
};
