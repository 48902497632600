import React, { useState } from 'react';

import { DEFAULT_APP_CONTEXT } from './app-context.constants';
import { IAppContext, IAppContextProviderProps } from './app-context.types';

export const AppContext = React.createContext<IAppContext>(DEFAULT_APP_CONTEXT);

export const AppContextProvider: React.FunctionComponent<IAppContextProviderProps> = ({
	children,
}: IAppContextProviderProps) => {
	const tmpSiteID = window.localStorage.getItem('SITE_ID');
	const tmpCompanyID = window.localStorage.getItem('COMPANY_ID');
	const tmpGateInID = window.localStorage.getItem('GATE_IN_ID');
	const tmpGateOutID = window.localStorage.getItem('GATE_OUT_ID');

	const [siteID, setStateSiteID] = useState<number>(tmpSiteID ? parseInt(tmpSiteID) : -1);
	const [companyID, setStateCompanyID] = useState<number>(tmpCompanyID ? parseInt(tmpCompanyID) : 0);
	const [gateInID, setStateGateInID] = useState<number>(tmpGateInID ? parseInt(tmpGateInID) : -1);
	const [gateOutID, setStateGateOutID] = useState<number>(tmpGateOutID ? parseInt(tmpGateOutID) : -1);

	const [sectionTitle, setSectionTitle] = useState<string>('');

	const setSiteID = (aID: number) => {
		window.localStorage.setItem('SITE_ID', `${aID}`);
		setStateSiteID(aID);
	};

	const setCompanyID = (aID: number) => {
		window.localStorage.setItem('COMPANY_ID', `${aID}`);
		setStateCompanyID(aID);
	};
	const setGateInID = (aID: number) => {
		window.localStorage.setItem('GATE_IN_ID', `${aID}`);
		setStateGateInID(aID);
	};

	const setGateOutID = (aID: number) => {
		window.localStorage.setItem('GATE_OUT_ID', `${aID}`);
		setStateGateOutID(aID);
	};

	return (
		<AppContext.Provider
			value={{
				context: {
					siteID: siteID,
					companyID: companyID,
					gateInID: gateInID,
					gateOutID: gateOutID,
					sectionTitle: sectionTitle,
					setSiteID: setSiteID,
					setCompanyID: setCompanyID,
					setGateInID: setGateInID,
					setGateOutID: setGateOutID,
					setSectionTitle: setSectionTitle,
				},
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = (): IAppContext => React.useContext(AppContext);
