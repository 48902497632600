export enum SIGN_TYPE {
	NONE = '',
	SIGN_IN = 'Sign In',
	SIGN_OUT = 'Sign Out',
}

export interface SignInOutMessage {
	SiteID: number;
	SignInMesssage: string;
	SignOutMesssage: string;
}
