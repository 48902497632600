import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../static/opms_logo.svg';

type FooterProps = {
	width: string | number;
};

// TODO: Fill this footer out with some useful information
export const Footer = ({ width }: FooterProps): React.FunctionComponentElement<FooterProps> => (
	<Box display="flex" justifyContent="center" width={width} p={1}>
		<Typography variant="caption">
			<Logo width="16" /> {`Powered by OPMS - Version ${process.env.REACT_APP_OPMS_VERSION}`}
		</Typography>
	</Box>
);
