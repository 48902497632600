import React, { useEffect } from 'react';

import { API } from 'api';

import { IndeterminateLoader } from 'components';

type SignInOutGateEntryProps = {
	employeeID: number;
	gateID: number;
	siteID: number;
	onSuccess: (aSignInOutTime: number) => void;
};

export const SignInOutGateEntry = ({
	employeeID,
	gateID,
	siteID,
	onSuccess,
}: SignInOutGateEntryProps): React.FunctionComponentElement<Record<string, unknown>> => {
	useEffect(() => {
		API.sendGateEntry(employeeID, gateID, siteID).then((result) => {
			if (result.isSuccess) {
				onSuccess(result.gateAddedTime);
			}
		});
	}, [employeeID, gateID, siteID, onSuccess]);
	return <IndeterminateLoader message="Sending Data..." />;
};
