import React from 'react';

// TODO: add logic to router when required
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// UI & Main components
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Footer } from './containers/footer/footer.container';
import { Header } from './containers/header/header.container';
import { makeStyles } from '@material-ui/core/styles';

import { AppRouter } from 'containers/app-router/app-router.container';

const App: React.FunctionComponent = () => {
	const useStyles = makeStyles({
		baseContainer: {
			padding: '50px 16px', // top,bottom=50, left,right=16
		},
	});

	const classes = useStyles();
	return (
		<Container maxWidth="sm" className={classes.baseContainer}>
			<Paper elevation={3}>
				<Header width="100%" py={2} />
				<Divider />
				<Box p={4}>
					<AppRouter />
				</Box>
				<Divider />
				<Footer width="100%" />
			</Paper>
		</Container>
	);
};

export default App;
