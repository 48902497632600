import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface IIndeterminateLoaderProps {
	message?: string;
}

export const IndeterminateLoader = ({
	message,
}: IIndeterminateLoaderProps): React.FunctionComponentElement<IIndeterminateLoaderProps> => {
	return (
		<Grid id="IndeterminateLoader" container alignItems="center" justify="center">
			<Grid item>
				<Box pr={1}>
					<CircularProgress />
				</Box>
			</Grid>
			<Grid item>
				<Typography variant="body2">{message}</Typography>
			</Grid>
		</Grid>
	);
};
