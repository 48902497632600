/**
 * Thank you message container.
 *
 * @author Max Maksymenko, Nathan Iszlaub
 * @date 08/06/2020
 */
import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import Employee from 'classes/Employee.class';
import { SIGN_TYPE } from '../../multi-step-wizard-form.types';
import { useTheme } from '@material-ui/core';

const SIGN_TYPE_MAP = {
	[SIGN_TYPE.NONE]: '',
	[SIGN_TYPE.SIGN_IN]: 'signing in',
	[SIGN_TYPE.SIGN_OUT]: 'signing out',
};

interface ISignInOutThankYouProps {
	employee: Employee;
	signType: SIGN_TYPE;
	timeoutMs: number;
	signInOutUnixTimestamp: number;
	onTimeout: () => void; // a function is triggered after `timeoutMs` if the user does not click on the "Done" button.
}

export const SignInOutThankYou = ({
	employee,
	signType,
	timeoutMs,
	signInOutUnixTimestamp,
	onTimeout,
}: ISignInOutThankYouProps): React.FunctionComponentElement<ISignInOutThankYouProps> => {
	useEffect(() => {
		let timeout: number;

		if (onTimeout) {
			timeout = window.setTimeout(() => {
				onTimeout();
			}, timeoutMs);
		}

		return () => {
			window.clearTimeout(timeout);
		};
	}, [timeoutMs, onTimeout]);

	const theme = useTheme();

	const formatUnixTimestamp = function (aUnixTimestamp: number): string {
		// Create a new JavaScript Date object based on the timestamp
		// multiplied by 1000 so that the argument is in milliseconds, not seconds.
		// source: https://stackoverflow.com/a/847196/4781215
		const date = new Date(aUnixTimestamp * 1000);
		const mins = date.getMinutes();
		const minsStr = mins < 10 ? `0${mins}` : `${mins}`;
		const hours = date.getHours();
		const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
		return `${date.toDateString()} - ${hoursStr}:${minsStr}`;
	};

	return (
		<Grid container spacing={2} justify="center">
			<Grid container item xs={12} justify="center">
				<Typography align="center" variant="caption">
					<CheckCircleOutlinedIcon
						style={{ fontSize: 100, opacity: 0.3, color: theme.palette.secondary.light }}
					/>
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography align="center" variant="h6">
					{formatUnixTimestamp(signInOutUnixTimestamp)}
				</Typography>
				<Typography align="center" variant="body1">
					Thank you for {SIGN_TYPE_MAP[signType]}, {employee.FirstName}!
				</Typography>
			</Grid>
		</Grid>
	);
};
