import React, { useEffect, useState } from 'react';

import { Button, Grid, NativeSelect, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { API } from 'api';
import { useAppContext } from 'containers/app-context/app-context.container';
import { IGate, ISite } from 'interfaces';
import { IndeterminateLoader } from 'components';

interface IConfigFormProps {
	onSuccess: () => void;
}

const GATE_TYPE_IN = 1;
const GATE_TYPE_OUT = 2;

export const ConfigForm = ({ onSuccess }: IConfigFormProps): React.FunctionComponentElement<IConfigFormProps> => {
	const { context } = useAppContext();
	const { setSectionTitle } = context;
	const [sites, setSites] = useState<ISite[]>([]);
	const [gates, setGates] = useState<IGate[]>([]);
	const [selectedSiteID, setSelectedSiteID] = useState<string>('');
	const [selectedGateInID, setSelectedGateInID] = useState<string>('');
	const [selectedGateOutID, setSelectedGateOutID] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);

	const noneItemOption = (
		<option disabled hidden key="" value="">
			Please Select
		</option>
	);

	const siteItems = [noneItemOption];
	sites.forEach((site) => {
		siteItems.push(
			<option key={site.ID} value={site.ID}>
				{site.Name}
			</option>,
		);
	});

	const gateInItems = [noneItemOption];
	const gateOutItems = [noneItemOption];
	gates.forEach((gate) => {
		if (gate.Site.toString() === selectedSiteID) {
			switch (gate.GateType) {
				case GATE_TYPE_IN:
					gateInItems.push(
						<option key={gate.ID} value={gate.ID}>
							{gate.Name}
						</option>,
					);
					break;
				case GATE_TYPE_OUT:
					gateOutItems.push(
						<option key={gate.ID} value={gate.ID}>
							{gate.Name}
						</option>,
					);
					break;
			}
		}
	});

	const handleSiteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedSiteID(event.target.value);
		setSelectedGateInID('');
		setSelectedGateOutID('');
	};

	const handleGateInChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedGateInID(event.target.value);
	};

	const handleGateOutChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedGateOutID(event.target.value);
	};

	const handleConfirm = () => {
		const site = sites.find((site) => site.ID === Number(selectedSiteID));
		if (context.setSiteID) context.setSiteID(Number(selectedSiteID));
		if (context.setCompanyID) context.setCompanyID(site ? site.CompanyID : 0);
		if (context.setGateInID) context.setGateInID(Number(selectedGateInID));
		if (context.setGateOutID) context.setGateOutID(Number(selectedGateOutID));
		onSuccess();
	};

	useEffect(() => {
		if (setSectionTitle !== null) setSectionTitle('Gate Configuration');
		setLoading(true);
		API.getSitesWithGates()
			.then((response) => {
				setSites(response);
				API.getActiveGatesBySiteID(response.map((site) => site.ID))
					.then((response) => {
						setLoading(false);
						setGates(response);
					})
					.catch((error) => {
						setLoading(false);
						console.error(error);
					});
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	}, [setSectionTitle]);

	if (loading) {
		return <IndeterminateLoader message="Loading Configuration Data..." />;
	} else if (sites.length === 0 || gates.length === 0) {
		return (
			<Alert severity="error">
				Failed to load {sites.length === 0 ? 'sites' : 'gates'}. Please try again later.
			</Alert>
		);
	} else {
		return (
			<Grid container spacing={2} justify="center">
				<Grid item xs={12}>
					<Typography variant="body2">Site:</Typography>
					<NativeSelect fullWidth onChange={handleSiteChange} value={selectedSiteID}>
						{siteItems}
					</NativeSelect>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2">Sign IN Gate:</Typography>
					<NativeSelect
						fullWidth
						disabled={selectedSiteID === ''}
						onChange={handleGateInChange}
						value={selectedGateInID}
					>
						{gateInItems}
					</NativeSelect>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2">Sign OUT Gate:</Typography>
					<NativeSelect
						fullWidth
						disabled={selectedSiteID === ''}
						onChange={handleGateOutChange}
						value={selectedGateOutID}
					>
						{gateOutItems}
					</NativeSelect>
				</Grid>
				<Grid item container xs={12} justify="space-between">
					<Grid container item xs={6} sm={4} justify="flex-start" />
					<Grid container item xs={6} sm={4} justify="flex-end">
						<Button
							color="primary"
							disabled={selectedSiteID === '' || selectedGateInID === '' || selectedGateOutID === ''}
							fullWidth
							onClick={handleConfirm}
							variant="contained"
						>
							CONFIRM
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}
};
