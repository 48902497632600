/**
 * String Utilities class.
 *
 * @author Max Maksymenko
 * @date 18/06/2020
 */

/**
 * Converts a string to Title-case format.
 *
 * @param aStr
 */
export function toTitleCase(aStr: string): string {
	const strArr = aStr.toLowerCase().split(' ');
	for (let i = 0; i < strArr.length; i++) {
		strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
	}
	return strArr.join(' ');
}
