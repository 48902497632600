import React, { useEffect, useState, useCallback } from 'react';

import { API, PIN_STATUS } from 'api';
import { useAppContext } from 'containers/app-context/app-context.container';

import Employee from 'classes/Employee.class';
import { IndeterminateLoader } from 'components';
import {
	PinEntry,
	PinSetup,
	SignInOut,
	SignInOutConfirmation,
	SignInOutGateEntry,
	SignInOutThankYou,
} from './containers';
import { SIGN_TYPE, SignInOutMessage } from './multi-step-wizard-form.types';

import { Alert, AlertTitle } from '@material-ui/lab';

enum STEPS {
	NONE,
	DATA_LOADING_EMPLOYEES,
	HOME,
	CUSTOM_MESSAGE,
	ENTER_PIN,
	SETUP_PIN,
	CREATE_GATE_ENTRY,
	THANK_YOU,
}

/**
 * Indicates the interval after which we need to re-fetch the employee list from the API.
 */
const RELOAD_EMPLOYEE_LIST_TIMEOUT_MINS = 60; // 60 mins

export const MultiStepWizardForm = (): React.FunctionComponentElement<Record<string, unknown>> => {
	enum EMPLOYEE_RELOAD_METHOD {
		TIMER = 1,
		MANUAL = 0,
	}

	const { context } = useAppContext();
	const [step, setStep] = useState<STEPS>(STEPS.NONE);
	const [signType, setSignType] = useState<SIGN_TYPE>(SIGN_TYPE.NONE);
	const [employee, setEmployee] = useState<Employee>(new Employee(-1, '', '', ''));
	const [employees, setEmployees] = useState<Array<Employee>>([]);
	const [signInOutMessageMap, setSignInOutMessageMap] = useState<Map<number, SignInOutMessage>>(new Map());
	const [pinStatus, setPinStatus] = useState<PIN_STATUS>(PIN_STATUS.NO_PIN);
	const [signInOutTimestamp, setSignInOutTimestamp] = useState<number>(-1);

	const displayErrorMessage = (
		aTitle: string,
		aMessage: string,
	): React.FunctionComponentElement<Record<string, unknown>> => {
		return (
			<Alert severity="error">
				<AlertTitle>{aTitle}</AlertTitle>
				{aMessage}
			</Alert>
		);
	};

	const loadEmployeeList = useCallback(async () => {
		try {
			const employees = await API.getEmployees(context.companyID);
			setEmployees(employees);
		} catch (error) {
			console.error('Error loading employees, error:', error);
		}
	}, [context.companyID]);

	const loadSignOnMessages = useCallback(async () => {
		try {
			const messages = await API.loadSignInOutMessagesForAccessibleSites();
			const messageMap = new Map<number, SignInOutMessage>();
			messages.forEach((message) => {
				messageMap.set(message.SiteID, message);
			});
			setSignInOutMessageMap(messageMap);
		} catch (error) {
			console.error('Error loading employees, error:', error);
		}
	}, []);

	const reloadEmployeeListAndSignOnMessages = useCallback(
		async (aReloadType: EMPLOYEE_RELOAD_METHOD) => {
			switch (aReloadType) {
				case EMPLOYEE_RELOAD_METHOD.TIMER:
					await loadEmployeeList();
					await loadSignOnMessages();
					break;
				case EMPLOYEE_RELOAD_METHOD.MANUAL:
					setStep(STEPS.DATA_LOADING_EMPLOYEES);
					await loadEmployeeList();
					await loadSignOnMessages();
					setStep(STEPS.HOME);
					break;
			}
		},
		[loadEmployeeList, loadSignOnMessages, EMPLOYEE_RELOAD_METHOD.TIMER, EMPLOYEE_RELOAD_METHOD.MANUAL],
	);

	const handleGateEntryOnSuccess = useCallback((aSignInOutTime) => {
		setSignInOutTimestamp(aSignInOutTime);
		setStep(STEPS.THANK_YOU);
	}, []);

	useEffect(() => {
		(async () => {
			await reloadEmployeeListAndSignOnMessages(EMPLOYEE_RELOAD_METHOD.MANUAL);
		})();

		// init the interval for automatically re-fetching the employee list every X seconds
		const interval = window.setInterval(() => {
			reloadEmployeeListAndSignOnMessages(EMPLOYEE_RELOAD_METHOD.TIMER);
		}, RELOAD_EMPLOYEE_LIST_TIMEOUT_MINS * 60 * 1000);

		return () => {
			window.clearInterval(interval);
		};
	}, [reloadEmployeeListAndSignOnMessages, EMPLOYEE_RELOAD_METHOD.MANUAL, EMPLOYEE_RELOAD_METHOD.TIMER]);

	if (context.gateInID <= 0 || context.gateOutID <= 0 || context.siteID < 0) {
		return displayErrorMessage('Configuration Error', 'Site or Gates In/Out have not been configured.');
	}

	switch (step) {
		case STEPS.DATA_LOADING_EMPLOYEES: {
			return <IndeterminateLoader message="Loading Employees..." />;
		}
		case STEPS.HOME:
			if (employees.length === 0) {
				return displayErrorMessage('Data Error', 'Could not load any employees.');
			}
			return (
				<SignInOut
					employees={employees}
					onSectionComplete={(
						aNewSignType: SIGN_TYPE,
						aSelectedEmployee: Employee,
						aPinStatus: PIN_STATUS,
					) => {
						setPinStatus(aPinStatus);
						setSignType(aNewSignType);
						setEmployee(aSelectedEmployee);
						setStep(STEPS.CUSTOM_MESSAGE);
					}}
					onTriggerEmployeeReload={() => {
						reloadEmployeeListAndSignOnMessages(EMPLOYEE_RELOAD_METHOD.MANUAL);
					}}
				/>
			);
		case STEPS.CUSTOM_MESSAGE:
			return (
				<SignInOutConfirmation
					signType={signType}
					onAccept={() => setStep(pinStatus === PIN_STATUS.PIN_EXISTS ? STEPS.ENTER_PIN : STEPS.SETUP_PIN)}
					signInMessage={
						signInOutMessageMap.get(context.siteID)?.SignInMesssage ??
						'I have attended todays pre-start meeting and have a clear understanding of the information provided to enable me to safely carry out my tasks today and I have arrived at work fit to perform my full duties.'
					}
					signOutMessage={
						signInOutMessageMap.get(context.siteID)?.SignOutMesssage ??
						'I have had no injuries, incidents or illnesses during todays shift.'
					}
				/>
			);
		case STEPS.ENTER_PIN:
			return (
				<PinEntry
					maxFailures={5}
					onFailure={() => {
						alert('The pin has been entered too many times.');
						setStep(STEPS.HOME);
					}}
					onCancel={() => {
						setStep(STEPS.HOME);
					}}
					onSuccess={() => {
						setStep(STEPS.CREATE_GATE_ENTRY);
					}}
					opmsID={employee.ID}
					signType={signType}
					username={employee.FullName}
				/>
			);
		case STEPS.SETUP_PIN:
			return (
				<PinSetup
					opmsID={employee.ID}
					minPinLength={4}
					maxPinLength={8}
					onCancel={() => {
						setStep(STEPS.HOME);
					}}
					onSuccess={() => {
						setStep(STEPS.CREATE_GATE_ENTRY);
					}}
					signType={signType}
					username={employee.FullName}
				/>
			);
		case STEPS.CREATE_GATE_ENTRY:
			return (
				<SignInOutGateEntry
					employeeID={employee.ID}
					gateID={signType === SIGN_TYPE.SIGN_IN ? context.gateInID : context.gateOutID}
					siteID={context.siteID}
					onSuccess={handleGateEntryOnSuccess}
				/>
			);
		case STEPS.THANK_YOU:
			return (
				<SignInOutThankYou
					signType={signType}
					employee={employee}
					signInOutUnixTimestamp={signInOutTimestamp}
					timeoutMs={4000} // 4 seconds
					onTimeout={() => {
						setStep(STEPS.HOME);
						setSignInOutTimestamp(-1);
					}}
				/>
			);
		default:
			return <div></div>;
	}
};
