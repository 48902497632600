import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography, Link } from '@material-ui/core';

import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';

import Employee from 'classes/Employee.class';
import { API, PIN_STATUS } from 'api';
import { useAppContext } from 'containers/app-context/app-context.container';
import { IndeterminateLoader } from 'components';
import { HTMLSelectItem } from './HTMLSelectItem.class';
import { SIGN_TYPE } from '../../multi-step-wizard-form.types';

const reArrayEmployees = (employees: Array<Employee>): Array<HTMLSelectItem> => {
	return employees.map((inEmployee: Employee) => {
		const value = `${inEmployee.FullName} [${inEmployee.ID}]`;
		return new HTMLSelectItem(inEmployee.ID, value);
	});
};

interface ISignInOutProps {
	employees: Employee[];
	onSectionComplete: (aSignType: SIGN_TYPE, aEmployee: Employee, aPinStatus: PIN_STATUS) => void;
	onTriggerEmployeeReload: () => void;
}

export const SignInOut = ({
	employees,
	onSectionComplete,
	onTriggerEmployeeReload,
}: ISignInOutProps): React.FunctionComponentElement<ISignInOutProps> => {
	const { setSectionTitle } = useAppContext().context;
	const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (setSectionTitle !== null) setSectionTitle('Employee Sign On');
	}, [setSectionTitle]);

	const handleSignOn = (signType: SIGN_TYPE) => {
		if (selectedEmployee) {
			setIsLoading(true);
			API.getPinStatus(selectedEmployee.ID).then(
				(pinStatus: PIN_STATUS) => onSectionComplete(signType, selectedEmployee, pinStatus),
				(err: Error) => {
					setIsLoading(false);
					alert('Connection failed. Please try again.');
				},
			);
		}
	};

	const handleAutocompleteChange = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: HTMLSelectItem | string | null,
		reason: AutocompleteChangeReason,
	) => {
		if (value) {
			if (value instanceof HTMLSelectItem) {
				const employee = employees.find((tmpEmployee) => value.id === tmpEmployee.ID);
				if (employee) {
					setSelectedEmployee(employee);
				} else {
					setSelectedEmployee(null);
				}
			}
		} else {
			setSelectedEmployee(null);
		}
	};

	const customAutocompleteFilter = (options: HTMLSelectItem[], state: { inputValue: string }) => {
		let filtered: HTMLSelectItem[] = [];
		const searchValue = state.inputValue;

		// only initiate the searching when then `inputValue` is at least 2 chars long
		if (searchValue.length >= 2) {
			// do a search based on "starts with"
			filtered = options.filter((option: HTMLSelectItem) =>
				option.value.toUpperCase().startsWith(searchValue.toUpperCase()),
			);
		}

		return filtered;
	};

	const RenderSignInOutButtons = () => {
		if (isLoading) {
			return (
				<Grid container item xs={12} justify="center">
					<IndeterminateLoader />
				</Grid>
			);
		} else {
			return (
				<React.Fragment>
					<Grid container item xs={6} sm={4} justify="flex-end">
						<Button
							id="SignInButton"
							color="primary"
							disabled={selectedEmployee === null || isLoading}
							fullWidth
							onClick={() => handleSignOn(SIGN_TYPE.SIGN_IN)}
							variant="contained"
						>
							Sign In
						</Button>
					</Grid>
					<Grid container item xs={6} sm={4} justify="flex-start">
						<Button
							color="primary"
							disabled={selectedEmployee === null || isLoading}
							fullWidth
							onClick={() => handleSignOn(SIGN_TYPE.SIGN_OUT)}
							variant="contained"
						>
							Sign Out
						</Button>
					</Grid>
				</React.Fragment>
			);
		}
	};

	return (
		<Grid container spacing={3} justify="center">
			<Grid container item xs={12} justify="center" spacing={1}>
				<Grid item xs={12}>
					<Typography variant="body2">Please enter your surname:</Typography>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						disabled={isLoading}
						freeSolo
						filterOptions={customAutocompleteFilter}
						options={reArrayEmployees(employees)}
						getOptionLabel={(option: HTMLSelectItem | undefined) =>
							option instanceof HTMLSelectItem ? option.value : ''
						}
						onChange={handleAutocompleteChange}
						getOptionSelected={(option, value) => option.id === value.id}
						renderInput={(params) => <TextField {...params} label="Surname" variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="caption">
						Cannot find your name?{' - '}
						<Link href="#" onClick={onTriggerEmployeeReload}>
							reload
						</Link>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} justify="center" spacing={2}>
				<RenderSignInOutButtons />
			</Grid>
		</Grid>
	);
};
