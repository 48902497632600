/**
 * Employee class.
 *
 * @author Max Maksymenko
 * @author Nathan
 * @date 03/06/2020
 */
export default class Employee {
	public ID: number;
	public FirstName: string;
	public MiddleName: string;
	public LastName: string;
	public FullName: string;

	constructor(id: number, firstname: string, middlename: string, lastname: string) {
		this.ID = id;
		this.FirstName = firstname;
		this.MiddleName = middlename;
		this.LastName = lastname;
		this.FullName = `${this.LastName.toUpperCase()} ${this.FirstName} ${this.MiddleName}`.trim();
	}
}
