import { windowConfig } from 'window.config';
import React, { useEffect, useState } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { API } from 'api';
import { useAppContext } from 'containers/app-context/app-context.container';
import { IndeterminateLoader } from 'components';
import { ConfigForm, MultiStepWizardForm } from './containers';
import { HelpDialog } from './components';
import { IGate, ISite } from 'interfaces';

enum SCREEN {
	NONE,
	AUTHENTICATION,
	CONFIGURE_SITE_AND_GATES,
	MULTI_STEP_WIZARD,
}

export const AppRouter = (): React.FunctionComponentElement<Record<string, string>> => {
	const { context } = useAppContext();
	const [screen, setScreen] = useState<SCREEN>(SCREEN.NONE);
	const [helpVisible, setHelpVisible] = useState<boolean>(false);
	let screenComponent = <React.Fragment />;

	useEffect(() => {
		const checkConfig = (): Promise<boolean> => {
			if (context.siteID !== -1 && context.gateInID !== -1 && context.gateOutID !== -1) {
				return new Promise((resolve) => {
					Promise.all([
						API.getSitesWithGates().then((sites: ISite[]) =>
							sites.find((site: ISite) => site.ID === context.siteID) ? true : false,
						),
						API.getActiveGatesBySiteID([context.siteID]).then(
							(gates: IGate[]) =>
								Boolean(
									gates.find((gate: IGate) => gate.ID === context.gateInID && gate.GateType === 1),
								) &&
								Boolean(
									gates.find((gate: IGate) => gate.ID === context.gateOutID && gate.GateType === 2),
								),
						),
					]).then((isValidArray: boolean[]) =>
						resolve(isValidArray.reduce((isAllValid, isValid) => isAllValid && isValid, true)),
					);
				});
			} else {
				return new Promise((resolve) => resolve(false));
			}
		};

		// NOTE: this assumes that the api-auth web UI is served at its origin
		const authentication_url = `${new URL(windowConfig.runConfig.auth_api_url).origin}?tenant=${
			windowConfig.runConfig.tenant
		}&redirect_uri=${window.location.protocol}//${window.location.host}`;
		const authorization_code = new URL(window.location.href).searchParams.get('authorization_code') ?? '';
		if (authorization_code) {
			API.login(authorization_code).then((success) => {
				if (success) {
					const url = new URL(window.location.href);
					url.searchParams.delete('authorization_code');
					window.location.href = url.toString();
				} else {
					// TODO: show message saying that authentication has failed
					//       and that the user has to log back in again
					setScreen(SCREEN.AUTHENTICATION);
					window.location.href = authentication_url;
				}
			});
		} else
			API.checkLoggedIn()
				.then((isLoggedIn) => {
					if (isLoggedIn) {
						checkConfig().then((isValid: boolean) =>
							isValid ? setScreen(SCREEN.MULTI_STEP_WIZARD) : setScreen(SCREEN.CONFIGURE_SITE_AND_GATES),
						);
					} else {
						setScreen(SCREEN.AUTHENTICATION);
						window.location.href = authentication_url;
					}
				})
				.catch((error) => {
					console.error(error);
					setScreen(SCREEN.AUTHENTICATION);
					window.location.href = authentication_url;
				});
	}, [context.siteID, context.gateInID, context.gateOutID]);

	switch (screen) {
		case SCREEN.NONE:
			screenComponent = <IndeterminateLoader message="Authenticating..." />;
			break;
		case SCREEN.AUTHENTICATION:
			screenComponent = <IndeterminateLoader message="Redirecting to authentication server..." />;
			break;
		case SCREEN.CONFIGURE_SITE_AND_GATES:
			screenComponent = <ConfigForm onSuccess={() => setScreen(SCREEN.MULTI_STEP_WIZARD)} />;
			break;
		case SCREEN.MULTI_STEP_WIZARD:
			screenComponent = <MultiStepWizardForm />;
			break;
		default:
			console.error("AppRouter: Attempting to access a screen that doesn't exist.");
			screenComponent = (
				<Typography align="center" variant="body2">
					How did you even get here!?
				</Typography>
			);
			break;
	}
	return (
		<React.Fragment>
			<Grid container spacing={1}>
				<Grid container item xs={11} alignItems="center">
					<Typography variant="h5">{context.sectionTitle}</Typography>
				</Grid>
				<Grid container item xs={1} alignItems="center" justify="flex-end">
					<IconButton color="primary" onClick={() => setHelpVisible(true)}>
						<HelpIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					{screenComponent}
				</Grid>
			</Grid>
			<HelpDialog open={helpVisible} onClose={() => setHelpVisible(false)} />
		</React.Fragment>
	);
};
