/**
 * The Sign In/Sign Out confirmation screen.
 *
 * @author Max Maksymenko, Nathan Iszlaub, Dash Rathnayaka
 * @date 08/06/2020
 * @modified 15/12/2022
 */
import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Alert, AlertTitle } from '@material-ui/lab';

import { SIGN_TYPE } from '../../multi-step-wizard-form.types';

interface IAcceptDeclineButtonsProps {
	onAccept: () => void;
}

interface ISignInOutConfirmationProps extends IAcceptDeclineButtonsProps {
	signType: SIGN_TYPE;
	signInMessage: string;
	signOutMessage: string;
}

const getTextWithLineBreaksAsHTML = (text: string): React.ReactFragment => {
	return text.split('\n').map((text, index) => (
		<React.Fragment key={`signonMessage_${Date.now().toString()}_${index}`}>
			{text}
			<br></br>
		</React.Fragment>
	));
};

export const SignInOutConfirmation = ({
	signType,
	signInMessage,
	signOutMessage,
	onAccept,
}: ISignInOutConfirmationProps): React.FunctionComponentElement<ISignInOutConfirmationProps> => {
	return (
		<Grid id="SignInOutConfirmation" container spacing={2} justify="space-around">
			<Grid item xs={12}>
				<Alert id="SignInOutConfirmationMessage" severity="info">
					<AlertTitle>{signType} Message:</AlertTitle>
					<Typography variant="body1">
						{getTextWithLineBreaksAsHTML(signType === SIGN_TYPE.SIGN_IN ? signInMessage : signOutMessage)}
					</Typography>
				</Alert>
			</Grid>
			<Grid container item xs={6} sm={4}>
				<Button
					id="SignInOutConfirmationAcceptButton"
					color="primary"
					fullWidth
					onClick={() => onAccept()}
					variant="contained"
				>
					Accept
				</Button>
			</Grid>
		</Grid>
	);
};
