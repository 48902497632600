import React from 'react';

import Button from '@material-ui/core/Button';
import { CircularProgress, PropTypes } from '@material-ui/core';

interface ILoadingButtonProps {
	id: string;
	color: PropTypes.Color;
	disabled: boolean;
	fullWidth: boolean;
	loaderSize: number | string;
	loading: boolean;
	onClick: () => void;
	value: string;
	variant: 'text' | 'outlined' | 'contained';
}

export const LoadingButton = ({
	id,
	color,
	disabled,
	fullWidth,
	loading,
	onClick,
	value,
	variant,
}: ILoadingButtonProps): React.FunctionComponentElement<ILoadingButtonProps> => {
	return (
		<Button
			id={id}
			color={color}
			disabled={disabled || loading}
			fullWidth={fullWidth}
			onClick={onClick}
			variant={variant}
		>
			{loading ? <CircularProgress color="secondary" size={25} /> : value}
		</Button>
	);
};
