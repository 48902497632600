/**
 * windowConfig
 *
 * interface for the `window` global variable which allows us to set the type of the settings we need to access
 *
 * @author Matthew
 * @author Nathan Iszlaub
 * @date 23/02/2023
 */

interface IConfig extends Window {
	runConfig: {
		webportal_api_url: string;
		auth_api_url: string;
		tenant: string;
	};
}

declare let window: IConfig;

export const windowConfig = window;
