import Employee from 'classes/Employee.class';
import { APIAuthenticator } from 'api/api-authenticator';
import { IGate, ISite } from 'interfaces';
import { toTitleCase } from 'classes/Utils.string';
import { v4 as uuidv4 } from 'uuid';
import { windowConfig } from 'window.config';
import { toSqlDatetime } from 'classes/Utils.date';
import { SignInOutMessage } from 'containers/app-router/containers/multi-step-wizard-form/multi-step-wizard-form.types';

const api = new APIAuthenticator(windowConfig.runConfig.webportal_api_url, windowConfig.runConfig.auth_api_url);
api.loadFromLocalStorage();

export enum PIN_STATUS {
	NO_PIN,
	PIN_EXISTS,
	PIN_LOCKED,
}

// FUNCTIONS

const login = async (authorization_code: string): Promise<boolean> =>
	(await api.authenticate(authorization_code)) || (await checkLoggedIn());

const checkLoggedIn = (): Promise<boolean> => api.loadFromLocalStorage().reauthenticate();

const getPinStatus = async (id: number): Promise<PIN_STATUS> => {
	const data = new FormData();
	data.append(`OPMSID`, `${id}`);
	const apiResult = await api.post<{ hasPinSetup: number }>('/account/pin/account.pin.getPinStatus.php', data);
	return apiResult.hasPinSetup ? PIN_STATUS.PIN_EXISTS : PIN_STATUS.NO_PIN;
};

const verifyPin = async (id: number, pin: string): Promise<boolean> => {
	const data = new FormData();
	data.append(`OPMSID`, `${id}`);
	data.append(`PIN`, pin);
	const apiResult = await api.post<{ pinIsCorrect: boolean }>('/account/pin/account.pin.verifyPin.php', data);
	return apiResult.pinIsCorrect ? true : false;
};

const setupPin = async (id: number, pin: string): Promise<boolean> => {
	const data = new FormData();
	data.append(`OPMSID`, `${id}`);
	data.append(`PIN`, pin);
	await api.post('/account/pin/account.pin.setPin.php', data);
	return true; // TODO:Consider checking the request status
};

const getEmployees = async (company: number): Promise<Array<Employee>> => {
	const employees: Array<Employee> = [];
	const data = new FormData();
	data.append(`company_id`, `${company}`);

	const apiResult = await api.post<{ staff: [Record<string, unknown>] }>(
		'/staff/staff.getAllStaffAtCompany.php',
		data,
	);

	const CreateEmployeeObject = (tmpEmployee: Record<string, unknown>): Employee | null => {
		if (tmpEmployee.OPMSID && tmpEmployee.FirstName && tmpEmployee.LastName) {
			const OPMSID: number = parseInt(`${tmpEmployee.OPMSID}`);
			const FirstName = toTitleCase(`${tmpEmployee.FirstName}`);
			const LastName = `${tmpEmployee.LastName}`.toUpperCase();
			return new Employee(OPMSID, FirstName, '', LastName);
		}
		return null;
	};

	const SortEmployeesByLastName = (aEmployees: Array<Employee>) => {
		aEmployees.sort((a: Employee, b: Employee): number => {
			if (a.LastName.toUpperCase() === b.LastName.toUpperCase()) return 0;
			return a.LastName.toUpperCase() < b.LastName.toUpperCase() ? -1 : 1;
		});
	};

	if (apiResult.staff !== undefined) {
		const employeesArr = apiResult.staff;
		if (employeesArr instanceof Array) {
			for (const i in employeesArr) {
				const employee = CreateEmployeeObject(employeesArr[i]);
				if (employee) {
					employees.push(employee);
				}
			}
		}
	}

	SortEmployeesByLastName(employees);
	return employees;
};

type SendGateEntryResult = {
	isSuccess: boolean;
	gateAddedTime: number; // unit timestamp format
};

const sendGateEntry = async (aEmployeeID: number, aGateID: number, aSiteID: number): Promise<SendGateEntryResult> => {
	const result: SendGateEntryResult = {
		isSuccess: false,
		gateAddedTime: -1,
	};

	const data = new FormData();
	data.append(
		`SignOnEntryJSONArr`,
		JSON.stringify([
			{
				ID: `{${uuidv4()}}`,
				// TODO: remove timestamp from here and let this be handled by the API!
				DateTimeStamp: toSqlDatetime(new Date()),
				Employee: `${aEmployeeID}`,
				GateID: `${aGateID}`,
				SiteID: `${aSiteID}`,
			},
		]),
	);

	const apiResult = await api.post<{ current_time: number }>('/signon/signon.entries.import.php', data);

	if (apiResult.current_time) {
		result.isSuccess = true;
		result.gateAddedTime = parseInt(`${apiResult.current_time}`);
	}

	return result;
};

const getSitesWithGates = async (): Promise<ISite[]> => {
	return await api.post<ISite[]>('/site/site.getSignOn.php');
};

const getActiveGatesBySiteID = async (siteIDs: number[]): Promise<IGate[]> => {
	const data = new FormData();
	siteIDs.forEach((siteID) => data.append('siteIDs[]', `${siteID}`));
	return await api.post<IGate[]>('/signon/signon.gates.getBySiteID.php', data);
};

const loadSignInOutMessagesForAccessibleSites = async (): Promise<SignInOutMessage[]> => {
	const data = new FormData();
	data.append('requestDevice', '2'); // '2' - Central Device
	return await api.post<SignInOutMessage[]>('/v2/signon/signon.get.SignonMessagesForSites.php', data);
};

export const API = {
	checkLoggedIn,
	getEmployees,
	getPinStatus,
	getSitesWithGates,
	getActiveGatesBySiteID,
	login,
	sendGateEntry,
	setupPin,
	verifyPin,
	loadSignInOutMessagesForAccessibleSites,
};
