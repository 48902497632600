import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../static/opms_logo.svg';

type HeaderProps = {
	py: number;
	width: string | number;
};

export const Header = ({ py, width }: HeaderProps): React.FunctionComponentElement<HeaderProps> => {
	return (
		<Box display="flex" width={width} py={py}>
			<Grid container item direction="column" justify="center" alignItems="center">
				<Grid item>
					<Logo width="180" />
				</Grid>
				<Grid item>
					<Typography variant="h4">OPMS Sign On</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
