/**
 * Date Utilities class.
 *
 * @author Dash Rathnayaka
 * @date 17/11/2022
 */

/**
 * Converts a Date to MySQL DateTime format with respect to timezone.
 * Source:https://stackoverflow.com/a/57390893
 */
export const toSqlDatetime = (inputDate: Date): string => {
	const date = new Date(inputDate);
	const dateWithOffest = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	return dateWithOffest.toISOString().slice(0, 19).replace('T', ' ');
};
