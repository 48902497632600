import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { API } from 'api';
import { useAppContext } from 'containers/app-context/app-context.container';
import { SIGN_TYPE } from '../../multi-step-wizard-form.types';

import { CancelButton, LoadingButton, PinField } from 'components';

interface PinEntryProps {
	maxFailures: number;
	onFailure: () => void;
	onCancel?: () => void;
	onSuccess: () => void;
	opmsID: number;
	signType: SIGN_TYPE;
	username: string;
}

export const PinEntry = (props: PinEntryProps): React.FunctionComponentElement<PinEntryProps> => {
	const { setSectionTitle } = useAppContext().context;
	const { maxFailures, onFailure, onCancel, onSuccess, opmsID, signType, username } = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [failCount, setFailCount] = useState<number>(0);
	const [currentPin, setCurrentPin] = useState<string>('');
	const [pinError, setPinError] = useState<{
		status: boolean;
		message: string;
	}>({ status: false, message: '' });

	useEffect(() => {
		const signTypeStr: string = signType === SIGN_TYPE.SIGN_IN ? 'Signing In' : 'Signing Out';
		if (setSectionTitle !== null) setSectionTitle(`${signTypeStr} - ${username}`);
	}, [setSectionTitle, signType, username]);

	async function handleConfirm() {
		setLoading(true);
		API.verifyPin(opmsID, currentPin).then(
			(validPin: boolean) => {
				if (validPin) {
					onSuccess();
				} else {
					setLoading(false);
					if (failCount >= maxFailures - 1) {
						onFailure();
					} else {
						setFailCount((failCount + 1) % maxFailures);
					}
				}
			},
			(err: Error) => {
				alert('Connection failed. Please try again.');
				setLoading(false);
			},
		);
	}

	const handlePinChange = (newValue: string) => {
		// TODO: These regular expressions are probably inefficient. We should consider optimizing this.
		const reDigits = /^\d*$/;
		if (!reDigits.test(newValue)) {
			setPinError({
				status: true,
				message: 'PINs can only contain digits',
			});
		} else {
			setPinError({ status: false, message: '' });
			setCurrentPin(newValue);
		}
	};

	const handlePinEnterKey = () => {
		if (currentPin !== '') {
			handleConfirm();
		}
	};

	return (
		<Grid container spacing={2} justify="center">
			<RetryCountAlertGridItem retriesLeft={failCount > 0 ? maxFailures - failCount : 0} />
			<Grid item xs={12}>
				<Typography variant="body2">Please enter your PIN:</Typography>
				<PinField
					id="PinEntryInput"
					error={pinError.status}
					helperText={pinError.message}
					onChange={handlePinChange}
					onEnterKey={handlePinEnterKey}
					value={currentPin}
				/>
			</Grid>
			<Grid
				item
				container
				xs={12}
				justify="space-between"
				direction="row-reverse" // NOTE: The direction here is `reverse` because we need to ensure the correct tab-order
			>
				<Grid container item xs={6} sm={4} justify="flex-end">
					<LoadingButton
						id="PinEntryConfirmButton"
						color="primary"
						disabled={currentPin.length === 0}
						fullWidth
						loaderSize={25}
						loading={loading}
						onClick={handleConfirm}
						value="Confirm"
						variant="contained"
					/>
				</Grid>
				<Grid container item xs={6} sm={4} justify="flex-start">
					<CancelButton
						id="PinEntryCancelButton"
						disabled={loading}
						onClick={() => {
							if (onCancel) {
								onCancel();
							}
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

const RetryCountAlertGridItem = (props: { retriesLeft: number }) => {
	if (props.retriesLeft !== 0) {
		return (
			<Grid item xs={12}>
				<Alert id="AlertError" severity="error">
					Incorrect Pin! ({props.retriesLeft} retries remaining)
				</Alert>
			</Grid>
		);
	} else {
		return null;
	}
};
