import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { API } from 'api';
import { useAppContext } from 'containers/app-context/app-context.container';
import { SIGN_TYPE } from '../../multi-step-wizard-form.types';

import { CancelButton, LoadingButton, PinField } from 'components';

interface PinSetupProps {
	maxPinLength: number;
	minPinLength: number;
	onCancel: () => void;
	onSuccess: () => void;
	opmsID: number;
	signType: SIGN_TYPE;
	username: string;
}

export const PinSetup = (props: PinSetupProps): React.FunctionComponentElement<PinSetupProps> => {
	const { setSectionTitle } = useAppContext().context;
	const { minPinLength, maxPinLength, onCancel, onSuccess, opmsID, signType, username } = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [currentPin, setCurrentPin] = useState<string>('');
	const [reentryPin, setReentryPin] = useState<string>('');
	const [currentPinError, setCurrentPinError] = useState<{
		status: boolean;
		message: string;
	}>({ status: false, message: '' });
	const [reentryPinError, setReentryPinError] = useState<{
		status: boolean;
		message: string;
	}>({ status: false, message: '' });

	useEffect(() => {
		const signTypeStr: string = signType === SIGN_TYPE.SIGN_IN ? 'Signing In' : 'Signing Out';
		if (setSectionTitle !== null) setSectionTitle(`${signTypeStr} - ${username}`);
	}, [setSectionTitle, signType, username]);

	useEffect(() => {
		if (reentryPin.length > 0 && currentPin !== reentryPin) {
			setReentryPinError({
				status: true,
				message: 'PINs are not the same',
			});
		} else {
			setReentryPinError({ status: false, message: '' });
		}
	}, [currentPin, reentryPin]);

	const handleConfirm = () => {
		setLoading(true);
		API.setupPin(opmsID, currentPin).then(
			(success: boolean) => {
				if (success) {
					onSuccess();
				} else {
					setLoading(false);
					setCurrentPin('');
					setReentryPin('');
					setCurrentPinError({ status: false, message: '' });
					setReentryPinError({ status: false, message: '' });
				}
			},
			(err: Error) => {
				alert('Connection failed. Please try again.');
				setLoading(false);
			},
		);
	};

	const handleCurrentPinChange = (newValue: string) => {
		// TODO: These regular expressions are probably ineffecient. We should consider optimizing this.
		const reDigits = /^\d*$/;
		if (!reDigits.test(newValue)) {
			setCurrentPinError({
				status: true,
				message: 'PIN can only contain digits',
			});
		} else {
			if (newValue.length < minPinLength) {
				setCurrentPinError({
					status: true,
					message: `PIN must be at least ${minPinLength} digits in length`,
				});
			} else if (newValue.length > maxPinLength) {
				setCurrentPinError({
					status: true,
					message: `PIN is limited to a max length of ${maxPinLength}`,
				});
			} else {
				setCurrentPinError({ status: false, message: '' });
			}
			setCurrentPin(newValue);
		}
	};

	const handleReentryPinChange = (newValue: string) => {
		// TODO: These regular expressions are probably ineffecient. We should consider optimizing this.
		const reDigits = /^\d*$/;
		if (!reDigits.test(newValue)) {
			setReentryPinError({
				status: true,
				message: 'PIN can only contain digits',
			});
		} else if (newValue.length > maxPinLength) {
			setCurrentPinError({
				status: true,
				message: `PIN is limited to a max length of ${maxPinLength}`,
			});
		} else {
			setReentryPin(newValue);
		}
	};

	const handlePinEnterKey = () => {
		if (canConfirm()) {
			handleConfirm();
		}
	};

	const canConfirm = () => {
		return !currentPinError.status && !reentryPinError.status && reentryPin.length > 0;
	};

	return (
		<Grid id="PinSetup" container spacing={2} justify="flex-end">
			<Grid item xs={12}>
				<Typography variant="body2">Please set up your PIN:</Typography>
				<PinField
					id="PinSetupInput"
					error={currentPinError.status}
					helperText={currentPinError.message}
					onChange={handleCurrentPinChange}
					onEnterKey={handlePinEnterKey}
					value={currentPin}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">Please re-enter your PIN:</Typography>
				<PinField
					id="PinSetupConfirmInput"
					error={reentryPinError.status}
					helperText={reentryPinError.message}
					onChange={handleReentryPinChange}
					onEnterKey={handlePinEnterKey}
					value={reentryPin}
				/>
			</Grid>
			<Grid
				item
				container
				xs={12}
				justify="space-between"
				direction="row-reverse" // NOTE: The direction here is `reverse` because we need to ensure the correct tab-order
			>
				<Grid container item xs={6} sm={4} justify="flex-end">
					<LoadingButton
						id="PinSetupConfirmButton"
						color="primary"
						disabled={!canConfirm()}
						fullWidth
						loaderSize={25}
						loading={loading}
						onClick={handleConfirm}
						value="Confirm"
						variant="contained"
					/>
				</Grid>
				<Grid container item xs={6} sm={4} justify="flex-start">
					<CancelButton
						id="PinSetupCancelButton"
						disabled={loading}
						onClick={() => {
							if (onCancel) {
								onCancel();
							}
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PinSetup;
