import { IAppContext, IContextData } from './app-context.types';

export const DEFAULT_AUTHENTICATION_CONTEXT: IContextData = {
	siteID: -1,
	gateInID: -1,
	gateOutID: -1,
	companyID: 0,
	sectionTitle: '',
	setSiteID: null,
	setCompanyID: null,
	setGateInID: null,
	setGateOutID: null,
	setSectionTitle: null,
};

export const DEFAULT_APP_CONTEXT: IAppContext = {
	context: DEFAULT_AUTHENTICATION_CONTEXT,
};
