import { createMuiTheme } from '@material-ui/core/styles';

const THEME_CONSTANTS = {
	colours: {
		blue: {
			// light: '',
			main: '#144A96',
			// dark: '',
		},
		green: {
			// light: '',
			main: '#0E9C47',
			// dark: '',
		},
	},
};

export const DEFAULT_THEME = createMuiTheme({
	// typography: {
	// 	fontFamily: 'Quicksand, sans-serif',
	// },
	palette: {
		primary: THEME_CONSTANTS.colours.blue,
		secondary: THEME_CONSTANTS.colours.green,
	},
});
