import { join as url_join } from '../helper/helper.url';

interface WebportalResponse<T> {
	data?: T;
	status?: number;
	message?: string;
}

export class APIAuthenticator {
	private mAuthToken: string;
	private mRenewToken: string;
	private mWebPortalAPIURI: string;
	private mAuthAPIURI: string;

	constructor(aWebPortalAPIURI?: string, aAuthAPIURI?: string) {
		this.mAuthToken = '';
		this.mRenewToken = '';
		this.mWebPortalAPIURI = aWebPortalAPIURI ?? '';
		this.mAuthAPIURI = aAuthAPIURI ?? '';
	}

	saveToLocalStorage(): void {
		window.localStorage.setItem('auth_token', this.mAuthToken);
		window.localStorage.setItem('renew_token', this.mRenewToken);
	}

	loadFromLocalStorage(): APIAuthenticator {
		this.mAuthToken = window.localStorage.getItem('auth_token') ?? this.mAuthToken;
		this.mRenewToken = window.localStorage.getItem('renew_token') ?? this.mRenewToken;
		return this;
	}

	getAuthToken(): string {
		return this.mAuthToken;
	}

	getRenewToken(): string {
		return this.mRenewToken;
	}

	setAuthToken(aAuthToken: string): void {
		this.mAuthToken = aAuthToken;
	}

	setRenewToken(aRenewToken: string): void {
		this.mRenewToken = aRenewToken;
	}

	post<T>(aResource: string, aData?: FormData): Promise<T> {
		const url = url_join(this.mWebPortalAPIURI, aResource);

		// Perform Request(s)
		return new Promise((resolve, reject) => {
			this.internalPost(url, aData)
				.then((response) => {
					switch (response.status) {
						case 200: // Success
							response.json().then(({ data, status, message }: WebportalResponse<T>) => {
								if (data === undefined || status === undefined || message === undefined)
									reject(new Error(`Invalid API response`));
								else if (status === 1) reject(new Error(`API Error: ${message}`));
								else resolve(data);
							});
							break;
						case 401: // `auth_token` expired
							this.reauthenticate().then((success) => {
								if (success) {
									this.internalPost(url, aData).then((response) => {
										if (response.status !== 200)
											reject(new Error(`Invalid request response: ${response.statusText}`));
										else
											response.json().then(({ data, status, message }: WebportalResponse<T>) => {
												if (data === undefined || status === undefined || message === undefined)
													reject(new Error(`Invalid API response`));
												else if (status === 1) reject(new Error(`API Error: ${message}`));
												else resolve(data);
											});
									});
								} else reject(new Error(`Failed to reauthenticate`));
							});
							break;
						default:
							reject(new Error(`Invalid request response: ${response.statusText}`));
					}
				})
				.catch((error) => {
					console.error('Fetch Error', error);
					reject(error);
				});
		});
	}

	async authenticate(authorization_code: string): Promise<boolean> {
		const { access_token, refresh_token } = await window
			.fetch(url_join(this.mAuthAPIURI, '/api/authenticate/token'), {
				method: 'POST',
				cache: 'no-cache',
				body: JSON.stringify({ authorization_code, grant_type: 'authorization_code' }),
				headers: { 'Content-Type': 'application/json' },
			})
			.then((response) => (response.status === 200 ? response.json() : { access_token: '', refresh_token: '' }));
		this.mAuthToken = access_token ?? '';
		this.mRenewToken = refresh_token ?? '';
		this.saveToLocalStorage();
		return Boolean(access_token && refresh_token);
	}

	async reauthenticate(): Promise<boolean> {
		const { access_token, refresh_token } = await window
			.fetch(url_join(this.mAuthAPIURI, '/api/authenticate/token'), {
				method: 'POST',
				cache: 'no-cache',
				body: JSON.stringify({ refresh_token: this.mRenewToken, grant_type: 'refresh_token' }),
				headers: { 'Content-Type': 'application/json' },
			})
			.then((response) => (response.status === 200 ? response.json() : { access_token: '', refresh_token: '' }));
		this.mAuthToken = access_token ?? '';
		this.mRenewToken = refresh_token ?? '';
		this.saveToLocalStorage();
		return Boolean(access_token && refresh_token);
	}

	private internalPost(aURL: string, aData?: FormData): Promise<Response> {
		return window.fetch(aURL, {
			method: 'POST',
			headers: { Authorization: `Bearer ${this.mAuthToken}` },
			cache: 'no-cache',
			body: aData,
		});
	}
}
