import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

interface IHelpDialogProps {
	onClose: () => void;
	open: boolean;
}

export const HelpDialog = ({ onClose, open }: IHelpDialogProps): React.FunctionComponentElement<IHelpDialogProps> => {
	return (
		<Dialog fullWidth onClose={onClose} open={open} scroll="paper">
			<DialogTitle>Help</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<b>Q. I can&#39;t find my name in the list.</b>
				</DialogContentText>
				<DialogContentText>
					A. You don&#39;t have a profile in the database. Please contact the office and once your profile has
					been added to the database click &#34;reload&#34;
				</DialogContentText>
				<DialogContentText>
					<b>Q. I have forgotten my PIN.</b>
				</DialogContentText>
				<DialogContentText>
					A. Please contact the office and request for your pin to be reset.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
