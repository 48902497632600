import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from './containers/app-context/app-context.container';

// themes
import { DEFAULT_THEME } from 'theme';

ReactDOM.render(
	<React.StrictMode>
		<AppContextProvider>
			<ThemeProvider theme={DEFAULT_THEME}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</AppContextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
